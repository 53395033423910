<template>
  <div class="card">
    <div class="card-body">
      <div v-if="isLoading" class="d-flex justify-content-center">
        <b-spinner></b-spinner>
      </div>
      <div class="d-flex" v-else v-for="dataItem in descriptionBases.data" :key="dataItem.id">
        <div class="flex-shrink-0">
          <img :src="`${endpoint}/images/icons/db.png`" class="service-icon">
        </div>
        <div class="flex-grow-1 ms-3">
          <h5>{{ dataItem.title }}</h5>
          <p><b>{{$t('ui.date')}}:</b> {{dataItem.released_at }} <br>
            <b v-if="dataItem.records_count !== null"><b>{{$t('base_source_date.savedLines_bases')}}</b>: </b>
            <span v-if="dataItem.records_count !== null">{{ beautyNumbers(dataItem.records_count)}}</span><br>
          <b>{{$t('ui.params')}}:</b> <span v-for="(param, k) in dataItem.params" :key="k">{{$t(`params.${param}`)}}{{dataItem.params.length -1 !== k ? ", ":""}}</span> </p>
        </div>
      </div>
      <!-- <iframe :srcdoc="descriptionBases"></iframe> -->
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import env from '@/configs/env.json'
let host = '';
if(location.hostname.includes('web')) {
  host = location.hostname.replace('web.', '');
  env.endpoint = `https://${host}`;
}
const endpoint = env.endpoint.replace('/api', '')
export default {
  name: "DescriptionBase",
  data() {
    return {
      isLoading: false,
      endpoint
    };
  },
  methods:{
    beautyNumbers(val) {
      let temp;
      if (typeof val !== 'undefined' && val !== null) {
        temp = val.toString()
        if (temp.length >= 7 && temp.length < 10) {
          let count = temp.length - 7;
          temp = temp.substr(0, temp.length - 3)
          let first = temp.substr(0, temp.length - 3)
          let lust = temp.substr(1 + count)
          return first + this.$t('base_source_date.separator') + lust + this.$t('base_source_date.million');
        } else if (temp.length >= 10) {
          let count = temp.length - 10;
          temp = temp.substr(0, temp.length - 6)
          let first = temp.substr(0, temp.length - 3)
          let lust = temp.substr(1 + count)
          return first + this.$t('base_source_date.separator') + lust + this.$t('base_source_date.billion');
        }
        return val.toLocaleString('ru')
      }
    },
    getData() {
      this.isLoading = true;
      this.$store.dispatch('ui/getDescriptionBases').then(() => {this.isLoading = false;});
    }
  },
  computed: {
    ...mapState({
      descriptionBases: state => state.ui.descriptionBases
    })
  },
  mounted() {
    this.getData(this.$i18n.locale);
  },
  watch: {
    '$i18n.locale': {
      handler() {
        this.getData();
      }
    }
  }
}
</script>


<style scoped>
iframe{
  width: 100%;
  height: calc(100vh - 60px);
}
img.service-icon {
  width: 40px;
}
b {
  font-weight: 600;
}
</style>